const appConfig = {
    apiPrefix: process.env.REACT_APP_API_ROUTE,
    assetPrefix:process.env.REACT_APP_ASSET_URL,
    authenticatedEntryPath: '/home',
    authenticatedStaffEntryPath: '/staff/patients',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '',
    enableMock: false
}

export default appConfig